import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useTranslation } from 'react-i18next';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { changeColorOnVisible } from 'hooks/changeColorOnScroll';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

const FictionSection = ({ href = '#fictionDetails', title, subTitle = 'ourStory', id = 'fiction' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div id={id} className="grid__item ">
      <ReactVisibilitySensor
        partialVisibility
        offset={300}
        onChange={visibility => changeColorOnVisible('#ff625e', visibility, dispatch)}
      >
        <div className="display-1 link-title link--takiri" href="#">
          {title}
          <span>{t(subTitle)}</span>
        </div>
      </ReactVisibilitySensor>
      <AnchorLink href={href}>
        <div className="scroll-for-more">
          <div>
            <span />
            {t('seeMore')}
          </div>
        </div>
      </AnchorLink>
    </div>
  );
};

FictionSection.propTypes = {
  href: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};
export default FictionSection;
